import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

import { setSnackbarToast } from "redux/UiStateSlice";
import { DeviceDetails } from "../types";
import RemoteAccessRDP from "./RemoteAccessRDP";
import { useNavigate, useParams } from "react-router-dom";
import { DeviceAccessMethod } from "common/enums";
import RemoteAccessSSH from "./RemoteAccessSSH";
import RemoteAccessVNC from "./RemoteAccessVNC";

const RemoteAccessTab: React.FC = () => {
  const dispatch = useDispatch();
  const { accessMethod } = useParams();
  const navigate = useNavigate();
  const isRunEffect = useRef(true);
  const [deviceDetails, setDeviceDetails] = useState<DeviceDetails>(
    {} as DeviceDetails
  );
  const getDeviceDetails = useCallback(async () => {
    const remoteAccess = sessionStorage.getItem("remote_access")
      ? sessionStorage.getItem("remote_access")
      : localStorage.getItem("remote_access");
    const accessDevice = JSON.parse(remoteAccess ?? "{}") as DeviceDetails;
    if (!accessDevice.deviceId) {
      dispatch(
        setSnackbarToast({
          message: "Please select proper device access",
          open: true,
          severity: "error",
        })
      );
      localStorage.setItem("remote_access", "");
      sessionStorage.setItem("remote_access", "");
      navigate("/portal/devices/summary");
    }
    setDeviceDetails(accessDevice);
  }, [dispatch, navigate]);

  useEffect(() => {
    if (isRunEffect.current) {
      getDeviceDetails();
      isRunEffect.current = false;
    }
  }, [getDeviceDetails]);

  const handleClose = () => {
    localStorage.setItem("remote_access", "");
    sessionStorage.setItem("remote_access", "");
    window.close();
  };

  return (
    <>
      {accessMethod?.toLowerCase() === DeviceAccessMethod.RDP &&
        deviceDetails.deviceId && (
          <RemoteAccessRDP
            open={false}
            device={deviceDetails}
            isHideDialog={true}
            onClose={handleClose}
          ></RemoteAccessRDP>
        )}
      {accessMethod?.toLowerCase() === DeviceAccessMethod.SSH &&
        deviceDetails.deviceId && (
          <RemoteAccessSSH
            open={false}
            device={deviceDetails}
            isHideDialog={true}
            onClose={handleClose}
          ></RemoteAccessSSH>
        )}
      {(accessMethod?.toLowerCase() === DeviceAccessMethod.HTTP ||
        accessMethod?.toLowerCase() === DeviceAccessMethod.HTTPS) &&
        deviceDetails.deviceId && (
          <RemoteAccessVNC
            open={false}
            device={deviceDetails}
            isHideDialog={true}
            onClose={handleClose}
          ></RemoteAccessVNC>
        )}
    </>
  );
};

export default RemoteAccessTab;
