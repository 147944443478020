import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";

import apiClient from "common/apiClientAxios";
import { isAxiosError } from "axios";

interface IUser {
  userId: string;
  username: string;
  password?: string;
  email: string;
  accessToken?: string;
  roleId?: string;
  roleName?: string;
  isLoggedIn: boolean;
  accessWindowDisplay?: string;
  deviceAccessPreference?: string;
}
interface ILogin {
  email?: string;
  username: string;
  password: string;
  portalType?: string;
}

export interface IUserState {
  userData: IUser;
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
}

const initialState: IUserState = {
  userData: {
    userId: "",
    username: "",
    password: "",
    email: "",
    accessToken: "",
    roleId: "",
    roleName: "",
    isLoggedIn: false,
  },
  status: "idle",
  error: null,
};

export const saveUser = createAsyncThunk(
  "user/saveUser",
  async (userData: IUserState, { rejectWithValue }) => {
    try {
      const response = await apiClient.post("/user", userData);
      return response.data;
    } catch (error: any) {
      const errorData =
        isAxiosError(error) && error.response?.data
          ? error.response.data.meta.message
          : String(error.message);
      return rejectWithValue(errorData);
    }
  }
);

export const loginUser = createAsyncThunk(
  "user/loginUser",
  async (userData: ILogin, { rejectWithValue }) => {
    try {
      const response = await apiClient.post("/auth/login", userData); //local-AD-login //login
      return response.data;
    } catch (error: any) {
      const errorData =
        isAxiosError(error) && error.response?.data
          ? error.response.data.meta.message
          : String(error.message);
      return rejectWithValue(errorData);
    }
  }
);

export const loginADUser = createAsyncThunk(
  "user/loginADUser",
  async (userData: ILogin, { rejectWithValue }) => {
    try {
      const response = await apiClient.post("/auth/local-AD-login", userData);
      return response.data;
    } catch (error: any) {
      const errorData =
        isAxiosError(error) && error.response?.data
          ? error.response.data.meta.message
          : String(error.message);
      return rejectWithValue(errorData);
    }
  }
);

const userStateSlice = createSlice({
  name: "userState",
  initialState,
  reducers: {
    setUserData(state, action: PayloadAction<IUser>) {
      state.userData = action.payload;
    },
    clearUserData(state) {
      state.userData = initialState.userData;
      state.status = "idle";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        const response = action.payload.data;
        state.userData = { ...response, isLoggedIn: true };
        localStorage.setItem("access_token", response.accessToken);
        localStorage.setItem("user", JSON.stringify(response));
        state.status = "succeeded";
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.status = "failed";
        state.error = (action.payload as string) ?? "Failed to login user";
      })
      .addCase(loginADUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(loginADUser.fulfilled, (state, action) => {
        const response = action.payload.data;
        state.userData = { ...response, isLoggedIn: true };
        localStorage.setItem("access_token", response.accessToken);
        localStorage.setItem("user", JSON.stringify(response));
        state.status = "succeeded";
      })
      .addCase(loginADUser.rejected, (state, action) => {
        state.status = "failed";
        state.error = (action.payload as string) ?? "Failed to login user";
      });
  },
});

export const { setUserData, clearUserData } = userStateSlice.actions;

export default userStateSlice.reducer;
