import React, { useCallback, useState, useRef, useEffect } from "react";

import {
  CssBaseline,
  Card,
  Drawer,
  Link,
  Button,
  CardHeader,
} from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import { setLoader, setSnackbarToast } from "redux/UiStateSlice";
import apiClient from "common/apiClientAxios";
import { useDispatch } from "react-redux";
import { Site, SiteFormMasterData } from "../types";
import AddSite from "./AddSite";
import DeleteSite from "./DeleteSite";
import SitesTable from "./SitesTable";
import EditSite from "./EditSite";
import { useContentStyles } from "common/styles/useContentStyles";
import { constants } from "common/constants";
import { getTimezone, hasPermission, isEndUser } from "common/helpers/utils";
import { DrawerHeader } from "common/styles/styledComponents";
import AddOperatorDialog from "./AddOperatorDialog";
import AddOwnerDialog from "./AddOwnerDialog";
import ResetSiteConnection from "./ResetSiteConnectionDialog";

export const siteDefaultValues = {
  siteId: "",
  name: "",
  alias: "",
  tags: "",
  operatorId: "",
  operatorName: "",
  ownerId: "",
  ownerName: "",
  type: "",
  typeId: "",
  powerProduction: "",
  powerProductionUnit: constants.POWER_PRODUCTION_UNIT,
  complianceLevel: "",
  groupId: "",
  groupName: "",
  addressLine: "",
  city: "",
  country: "",
  state: "",
  countryId: "",
  stateId: "",
  zipcode: "",
  latitude: "",
  longitude: "",
  contactName: "",
  contactPhone: "",
  contactEmail: "",
  contactCompany: "",
  image: "",
  layout: "",
  inboundIpAddr: "",
  maxInboundPort: "",
  minInboundPort: "",
  timezone: getTimezone(),
  isGuacdConfig: process.env.REACT_APP_SITE_GUACD_CONFIG ?? "No",
};
const Sites: React.FC = () => {
  const classes = useContentStyles();
  const timerRef = useRef<number>();
  const dispatch = useDispatch();
  const TIMEOUT = 1000;
  const [openForm, setOpenForm] = useState(false);
  const [isUpdate, setUpdate] = useState(true);
  const [isEdit, setEdit] = useState(false);
  const [selectedSite, setSelectedSite] = useState<Site>(siteDefaultValues);
  const [openDialogue, setOpenDialogue] = React.useState(false);
  const [openResetDialogue, setOpenResetDialogue] = React.useState(false);
  const [sites, setSites] = useState<Site[]>([]);

  const updateTable = useCallback(async () => {
    try {
      const sitesResponse = await apiClient.get("/sites");
      setSites(sitesResponse.data.data as Site[]);
    } catch (error: any) {
      const errorData =
        error.response?.data?.meta?.message || String(error.message);
      dispatch(
        setSnackbarToast({
          message: errorData,
          open: true,
          severity: "error",
        })
      );
    }
  }, [dispatch]);

  useEffect(() => {
    if (isUpdate) {
      updateTable();
      setUpdate(false);
    }
    return () => clearTimeout(timerRef.current);
  }, [isUpdate, updateTable]);

  const canCreateSite = () => {
    const customerType = process.env.REACT_APP_CUSTOMER_TYPE?.toLowerCase();

    // Determine if the customer type can create sites
    const canCreateForCustomerType =
      customerType !== "medium" || sites.length === 0;

    return (
      !isEndUser() &&
      hasPermission("sites.summary", "write") &&
      canCreateForCustomerType
    );
  };

  const openAddForm = () => {
    canCreateSite() && setOpenForm(true);
  };
  const onCloseForm = () => {
    setOpenForm(false);
  };
  const onCloseEdit = () => {
    setEdit(false);
  };
  const onSaveUpdateTable = () => {
    timerRef.current = window.setTimeout(() => {
      setEdit(false);
      setOpenForm(false);
      setUpdate(true);
    }, TIMEOUT);
  };
  const onDeleteSite = async () => {
    try {
      if (selectedSite?.siteId) {
        const siteResponse = await apiClient.delete(
          `/sites/${selectedSite.siteId}`
        );
        dispatch(
          setSnackbarToast({
            message: siteResponse.data.meta.message,
            open: true,
            severity: "success",
          })
        );
        onDeleteUpdateTable();
      }
    } catch (error: any) {
      const errorData =
        error.response?.data?.meta?.message || String(error.message);
      dispatch(
        setSnackbarToast({
          message: errorData,
          open: true,
          severity: "error",
        })
      );
      setOpenDialogue(false);
    }
  };

  const onResetSiteConnection = async () => {
    try {
      if (selectedSite?.siteId && selectedSite?.allInboundPorts) {
        const siteResponse = await apiClient.put(
          `/sites/${selectedSite.siteId}/resetConnections`,
          selectedSite
        );
        dispatch(
          setSnackbarToast({
            message: siteResponse.data.meta.message,
            open: true,
            severity: "success",
          })
        );
        setOpenResetDialogue(false);
        setUpdate(true);
      } else {
        setOpenResetDialogue(false);
        dispatch(
          setSnackbarToast({
            message: "Site inbound IP and Ports are missing",
            open: true,
            severity: "error",
          })
        );
      }
    } catch (error: any) {
      const errorData =
        error.response?.data?.meta?.message || String(error.message);
      dispatch(
        setSnackbarToast({
          message: errorData,
          open: true,
          severity: "error",
        })
      );
      setOpenResetDialogue(false);
    }
  };
  const handleDelete = (site: Site) => {
    setSelectedSite(site);
    setOpenDialogue(true);
  };
  const resetConnection = (site: Site) => {
    setSelectedSite(site);
    setOpenResetDialogue(true);
  };
  const onDeleteUpdateTable = () => {
    setOpenDialogue(false);
    setUpdate(true);
  };
  const onCancelRemove = () => {
    setOpenDialogue(false);
  };
  const onEditSite = (site: Site) => {
    setSelectedSite(site);
    setEdit(true);
  };

  const isRunEffect = useRef(true);
  const [siteFormMasterData, setSiteFormMasterData] =
    useState<SiteFormMasterData>({
      siteTypes: [],
      siteOwners: [],
      siteOperators: [],
      groups: [],
      countries: [],
      complianceLevels: [],
      states: [],
    });

  const getMasterDataForSite = useCallback(async () => {
    try {
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: true,
        })
      );
      const formMasterResponse = await apiClient.get(`/sites/form-data`);
      const formMasters = formMasterResponse.data.data as SiteFormMasterData;
      setSiteFormMasterData(formMasters);
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: false,
        })
      );
    } catch (error: any) {
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: false,
        })
      );
      const errorData =
        error.response?.data?.meta?.message || String(error.message);
      dispatch(
        setSnackbarToast({
          message: errorData,
          open: true,
          severity: "error",
        })
      );
    }
  }, [dispatch]);

  React.useEffect(() => {
    if (isRunEffect.current) {
      getMasterDataForSite();
      isRunEffect.current = false;
    }
  }, [getMasterDataForSite, isRunEffect]);

  const [openAddOperatorDialog, setOpenAddOperatorDialog] =
    React.useState(false);
  const getOperators = async () => {
    try {
      const siteOperatorsResponse = await apiClient.get("/site-operators");
      setSiteFormMasterData((prevState) => ({
        ...prevState,
        siteOperators: siteOperatorsResponse.data.data,
      }));
    } catch (error: any) {
      const errorData =
        error.response?.data?.meta?.message || String(error.message);
      dispatch(
        setSnackbarToast({
          message: errorData,
          open: true,
          severity: "error",
        })
      );
    }
    setOpenAddOperatorDialog(false);
  };

  const [openAddOwnerDialog, setOpenAddOwnerDialog] = React.useState(false);
  const getOwners = async () => {
    try {
      const siteOwnersResponse = await apiClient.get("/site-owners");
      setSiteFormMasterData((prevState) => ({
        ...prevState,
        siteOwners: siteOwnersResponse.data.data,
      }));
    } catch (error: any) {
      const errorData =
        error.response?.data?.meta?.message || String(error.message);
      dispatch(
        setSnackbarToast({
          message: errorData,
          open: true,
          severity: "error",
        })
      );
    }
    setOpenAddOwnerDialog(false);
  };

  const handleFavoriteSite = async (site: Site) => {
    try {
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: true,
        })
      );
      if (site.isFavorite) {
        await apiClient.delete(`users/favoriteSite/${site.siteId}`);
      } else {
        await apiClient.put(`users/favoriteSite/${site.siteId}`);
      }
      const sitesResponse = await apiClient.get("/sites");
      setSites(sitesResponse.data.data as Site[]);
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: false,
        })
      );
    } catch (error: any) {
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: false,
        })
      );
      const errorData =
        error.response?.data?.meta?.message || String(error.message);
      dispatch(
        setSnackbarToast({
          message: errorData,
          open: true,
          severity: "error",
        })
      );
    }
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <div className={classes.contentPadding}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="none" color="inherit">
            Sites
          </Link>
        </Breadcrumbs>
      </div>
      <Card elevation={0} className={classes.contentSection}>
        <CardHeader
          disableTypography
          title="Sites"
          className={classes.cardHeader}
          action={
            <>
              {canCreateSite() && (
                <Button
                  onClick={openAddForm}
                  color="info"
                  startIcon={<PlaylistAddIcon />}
                >
                  {"Add Site"}
                </Button>
              )}
            </>
          }
        ></CardHeader>
        <Drawer
          anchor="right"
          open={openForm}
          classes={{ paper: classes.formPaper }}
        >
          <DrawerHeader />
          <AddSite
            siteFormMasterData={siteFormMasterData}
            onSaveUpdateTable={onSaveUpdateTable}
            onClose={onCloseForm}
            openOperatorForm={() => setOpenAddOperatorDialog(true)}
            openOwnerForm={() => setOpenAddOwnerDialog(true)}
          ></AddSite>
        </Drawer>
        <Drawer
          anchor="right"
          open={isEdit}
          classes={{ paper: classes.formPaper }}
        >
          <DrawerHeader />
          <EditSite
            siteFormMasterData={siteFormMasterData}
            onSaveUpdateTable={onSaveUpdateTable}
            onClose={onCloseEdit}
            selectedSite={selectedSite}
            openOperatorForm={() => setOpenAddOperatorDialog(true)}
            openOwnerForm={() => setOpenAddOwnerDialog(true)}
          ></EditSite>
        </Drawer>
        {openDialogue && (
          <DeleteSite
            onDeleteSite={onDeleteSite}
            selectedSite={selectedSite}
            open={openDialogue}
            onCancelRemove={onCancelRemove}
          ></DeleteSite>
        )}
        {openResetDialogue && (
          <ResetSiteConnection
            onResetSite={onResetSiteConnection}
            selectedSite={selectedSite}
            open={openResetDialogue}
            onCancelReset={() => setOpenResetDialogue(false)}
          ></ResetSiteConnection>
        )}
        {openAddOperatorDialog && (
          <AddOperatorDialog
            open={openAddOperatorDialog}
            onCancel={() => setOpenAddOperatorDialog(false)}
            onSave={getOperators}
          ></AddOperatorDialog>
        )}
        {openAddOwnerDialog && (
          <AddOwnerDialog
            open={openAddOwnerDialog}
            onCancel={() => setOpenAddOwnerDialog(false)}
            onSave={getOwners}
          ></AddOwnerDialog>
        )}
        <SitesTable
          data={sites}
          siteFormMasterData={siteFormMasterData}
          handleEditSite={onEditSite}
          handleDeleteSite={handleDelete}
          resetSiteConnection={resetConnection}
          handleFavoriteSite={handleFavoriteSite}
        ></SitesTable>
      </Card>
    </React.Fragment>
  );
};

export default Sites;
